import { useEffect, useState } from "react";
import arrayUtilities from "../../../utilities/arrayUtilities";
import { Marker, Polyline, Popup, useMap, useMapEvents } from "react-leaflet";
import { Button, Form } from "react-bootstrap";
import stringUtilities from "../../../utilities/stringUtilities";
import enums from "../../../utilities/enums";
import pointUtilities from "../../../utilities/pointUtilities";
import { divIcon } from "leaflet";
import cloneDeep from 'lodash.clonedeep';
import DeleteButton from "../../common/deleteButton";
import geospatialUtilities from "../../../utilities/geospatialUtilities";
import postal from "postal";
import WhenMapIsNotDrawingOrEditing from "../whenMapIsNotDrawingOrEditing";
import ConfigurationDialog from "./_configurationDialog";
import WorkflowSelect from "../../form/workflowSelect";
import * as turf from '@turf/turf'
import LimitSelect from "../../form/limitSelect";
import ScaleSlider from "../../form/scaleSlider";
import SnappableMarker from "../snappableMarker";
import SnapToPoint from "../snapToPoint";
import RotationSlider from "../../form/rotationSlider";

export default function WorkflowDrawingTool(props) {

    const [isDrawing, setIsDrawing] = useState(props.isDrawing);
    const [isEditing, setIsEditing] = useState(props.isEditing);
    const [isVisible, setIsVisible] = useState(false);
    const [doSnapToPoint, setDoSnapToPoint] = useState(null);

    const [printingSketchLimitIndex, setPrintingSketchLimitIndex] = useState(null);
    const [printingWorkflowId, setPrintingWorkflowId] = useState(null);
    const [renderPositions, setRenderPositions] = useState(null);

    const [types, setTypes] = useState([]);

    const [coords, setCoords] = useState(null);
    const [workflowId, setWorkflowId] = useState(null);
    const [limitId, setLimitId] = useState(null);
    const [type, setType] = useState(null);
    const [scale, setScale] = useState(null);
    const [rotate, setRotate] = useState(null);

    const [geometryType, setGeometryType] = useState(enums.GeometryType[0]);
    const [color, setColor] = useState(null);
    const [isDashed, setIsDashed] = useState(false);

    const [hasIcon, setHasIcon] = useState(false);
    const [iconUrl, setIconUrl] = useState(null);
    const [defaultIcon, setDefaultIcon] = useState(null);

    const [midPoints, setMidPoints] = useState(null);
    const [renderMidPoints, setRenderMidPoints] = useState(null);
    const [isDefaultMidPoints, setIsDefaultMidPoints] = useState(false);

    const map = useMap();

    const reset = function () {

        let coords = props.sketchItem?.coords ?? []
        setCoords(coords);
        setWorkflowId(props.sketchItem?.tool?.properties?.workflowId ?? "");
        setLimitId(props.sketchItem?.tool?.properties?.limitId ?? props.activeLimit);
        setType(props.sketchItem?.tool?.properties?.type ?? "");
        setScale(props.sketchItem?.tool?.properties?.scale ?? 1);
        setRotate(props.sketchItem?.tool?.properties?.rotate ?? 0);

        if (!arrayUtilities.isNullOrEmpty(props.sketchItem?.tool?.properties?.midPoints)) {
            setMidPoints(props.sketchItem.tool.properties.midPoints);
            setIsDefaultMidPoints(false);
        } else {
            setMidPoints(defaultMidPoints(coords));
            setIsDefaultMidPoints(true);
        }
    }

    const onDelete = function () {

        if (props.onDelete) {
            props.onDelete(props.index);
        }
    }

    const onCancel = function () {

        if (props.onCancel) {
            props.onCancel();
        }

        setIsDrawing(false);
        setIsEditing(false);
        reset();
    }

    const onSave = function () {

        if (props.onSave) {

            let sketchItem = {
                coords,
                tool: {
                    id: 16,
                    properties: {
                        workflowId,
                        limitId,
                        type,
                        scale,
                        rotate,
                        midPoints: isDefaultMidPoints ? null : midPoints
                    },
                    workflowIds: [
                        workflowId
                    ]
                }
            }
            props.onSave(sketchItem, props.index);
        }

        setIsDrawing(props.isDrawing)
        setIsEditing(props.isEditing);
        reset();
    }

    const defaultMidPoints = function (coords) {

        let result = [];
        if (!arrayUtilities.isNullOrEmpty(coords)) {
            for (let i = 0; i < coords.length; i++) {
                if (i > 0) {
                    let p0 = { lat: coords[i - 1].lat, lng: coords[i - 1].lng };
                    let p1 = { lat: coords[i].lat, lng: coords[i].lng };

                    result.push(geospatialUtilities.midPoint(p0, p1));
                };
            }
        }

        return result;
    }

    const deleteMidPoint = function (index) {

        map.closePopup();

        let copy = cloneDeep(midPoints);
        copy.splice(index, 1);
        setMidPoints(copy);
        setIsDefaultMidPoints(false);
    }

    const onMidPointMarkerDragEnd = function (e, index) {

        let copy = cloneDeep(midPoints);
        copy.splice(index, 1, e.target._latlng);
        setMidPoints(copy);
        setIsDefaultMidPoints(false);
    }

    const deletePoint = function (e, index) {

        map.closePopup();

        let copy = cloneDeep(coords);
        copy.splice(index, 1);
        setCoords(copy);

        if (isDefaultMidPoints) {
            setMidPoints(defaultMidPoints(copy));
        }
    }

    const onMarkerDragEnd = function (e, index) {

        let copy = cloneDeep(coords);
        copy.splice(index, 1, e.target._latlng);
        setCoords(copy);

        if (isDefaultMidPoints) {
            setMidPoints(defaultMidPoints(copy));
        }
    }


    const onAddPoint = function (latlng) {

        setDoSnapToPoint(null);

        let newcoords = arrayUtilities.newOrConcat(coords, latlng);
        setCoords(newcoords);

        if (isDefaultMidPoints) {
            setMidPoints(defaultMidPoints(newcoords));
        }
    }

    const onSnappableMarkerDragEnd = function (latlng, index) {

        let copy = cloneDeep(coords);
        copy.splice(index, 1, latlng);
        setCoords(copy);

        if (isDefaultMidPoints) {
            setMidPoints(defaultMidPoints(copy));
        }
    }

    useMapEvents({
        click: (e) => {
            if (props.isDrawing) {
                setDoSnapToPoint(e.latlng);
            }
        },
    })

    useEffect(() => {
        setRenderPositions(!arrayUtilities.isNullOrEmpty(coords) ? geospatialUtilities.coordsToLatLngArray(coords) : []);
    }, [coords]);

    useEffect(() => {

        let types = [];

        if (!stringUtilities.isNullOrEmpty(workflowId)) {
            let workflow = props.workflows.find(x => x.id === workflowId);

            if (workflow && workflow !== null) {
                let configuration = JSON.parse(workflow.configuration);

                if (!arrayUtilities.isNullOrEmpty(configuration.types)) {
                    types = types.concat(configuration.types.map(x => x.name));
                }

                if (!arrayUtilities.isNullOrEmpty(configuration.types2)) {
                    types = types.concat(configuration.types2.map(x => x.name));
                }

                types = arrayUtilities.distinct(types);
                types.sort();

                setTypes(types);
            }
        }

        setTypes(types);
    }, [workflowId])

    useEffect(() => {

        let workflowType = null;
        let iconUrl = null;


        if (!stringUtilities.isNullOrEmpty(workflowId) && !stringUtilities.isNullOrEmpty(type)) {
            let workflow = props.workflows.find(x => x.id === workflowId);

            if (workflow && workflow !== null) {
                let configuration = JSON.parse(workflow.configuration);

                if (!arrayUtilities.isNullOrEmpty(configuration.types)) {
                    workflowType = configuration.types.find(x => x.name === type);
                }

                if ((!workflowType || workflowType === null) && !arrayUtilities.isNullOrEmpty(configuration.types2)) {
                    workflowType = configuration.types2.find(x => x.name === type);
                }
            }
        }

        if (workflowType && workflowType !== null) {
            switch (workflowType.geometryType) {
                case "Line":
                    setGeometryType(enums.GeometryType[1]);
                    break;
                default:
                    setGeometryType(enums.GeometryType[0]);
                    break;
            }

            setColor(pointUtilities.getColorFromWorkflowAndType(workflowId, type, props.workflows));
            iconUrl = pointUtilities.getIconUrlFromWorkflowAndType(workflowId, type, props.workflows);

            setIsDashed(workflowType.isDashed);

        } else {
            setGeometryType(enums.GeometryType[0]);
            setColor("#000000");
            setIsDashed(false);
        }

        setHasIcon(!stringUtilities.isNullOrEmpty(iconUrl));
        setIconUrl(iconUrl);

    }, [type, props.symbolScale])

    let icon = hasIcon ? divIcon({
        html: `<img src="${iconUrl}" style="transform: rotate(${rotate ?? 0}deg);">`,
        className: "marker-icon-point",
        iconAnchor: [30 * scale * 0.5, 30 * scale * 0.5],
        iconSize: [30 * scale, 30 * scale]
    }) : defaultIcon;

    useEffect(() => {
        setDefaultIcon(divIcon({
            html: `<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><circle cx="8" cy="8" r="8" fill="${color}" /></svg>`,
            className: "marker-icon-point",
            iconAnchor: [8, 8],
            iconSize: [16, 16]
        }));
    }, [color]);

    useEffect(() => {

        if (isEditing) {
            postal.publish({
                channel: "map",
                topic: "isDrawingOrEditing",
                data: { value: true }
            })
        }

        map.closePopup();
    }, [isEditing])

    useEffect(() => {

        let pointsLinesIsDefault = arrayUtilities.isNullOrEmpty(props.workflowPoints) && arrayUtilities.isNullOrEmpty(props.workflowLines) && arrayUtilities.isNullOrEmpty(props.workflowPointsLines);
        let workflowIsVisible = stringUtilities.isNullOrEmpty(workflowId) || pointsLinesIsDefault || props.workflowPoints.includes(workflowId) || props.workflowLines.includes(workflowId) || props.workflowPointsLines.includes(workflowId);

        setIsVisible(isDrawing || isEditing || workflowIsVisible);
    }, [isDrawing, isEditing, workflowId, props.workflowLines, props.workflowPoints, props.workflowPointsLines])


    useEffect(() => {
        if (isVisible && !arrayUtilities.isNullOrEmpty(coords)) {
            let renderPositions = geospatialUtilities.coordsToLatLngArray(coords);
            let renderMidPoints = midPoints;

            if ((printingSketchLimitIndex !== null) || !stringUtilities.isNullOrEmpty(printingWorkflowId)) {

                let workflow = arrayUtilities.isNullOrEmpty(props.workflows) ? null : props.workflows.find(x => x.id === workflowId);

                if (!stringUtilities.isNullOrEmpty(limitId) && !arrayUtilities.isNullOrEmpty(props.sketch?.limits) && props.sketch.limits.findIndex(x => x.id === limitId) !== printingSketchLimitIndex) {
                    renderPositions = null;
                    renderMidPoints = [];
                }

                if (renderPositions !== null && !stringUtilities.isNullOrEmpty(printingWorkflowId) && printingWorkflowId !== "ALL" && printingWorkflowId !== workflowId && (!workflow || workflow === null || !workflow.isComposite)) {
                    renderPositions = null;
                    renderMidPoints = [];
                }

                if (renderPositions !== null && printingSketchLimitIndex !== null && !isNaN(printingSketchLimitIndex) && props.sketch && props.sketch !== null && !arrayUtilities.isNullOrEmpty(props.sketch.limits) && props.sketch.limits.length > printingSketchLimitIndex) {

                    let limit = props.sketch.limits[printingSketchLimitIndex];

                    if (limit !== null) {

                        let clipToPolygon = workflow.isComposite ?
                            (!arrayUtilities.isNullOrEmpty(limit.limitOfSketchCoords) ? geospatialUtilities.boundsToPolygonCoords(limit.limitOfSketchCoords) : null) :
                            (!arrayUtilities.isNullOrEmpty(limit.limitOfLocateCoords) ? limit.limitOfLocateCoords : null);

                        if (!arrayUtilities.isNullOrEmpty(clipToPolygon) && coords.length > 1) {

                            if (geometryType == enums.GeometryType[0]) {

                                renderPositions = [];

                                coords.forEach((coord, coordIndex) => {
                                    if (geospatialUtilities.polygonContainsPoint(clipToPolygon, coord)) {
                                        renderPositions.push([coord.lat, coord.lng]);
                                    }
                                });

                            }
                            else if (geometryType === enums.GeometryType[1]) {

                                renderPositions = [];

                                coords.forEach((coord, coordIndex) => {

                                    if (coordIndex > 0) {
                                        let prevCoord = coords[coordIndex - 1];

                                        let line = turf.lineString([[prevCoord.lat, prevCoord.lng], [coord.lat, coord.lng]]);

                                        let poly = turf.polygon([geospatialUtilities.makePolygonSelfClosing(
                                            geospatialUtilities.coordsToLatLngArray(clipToPolygon)
                                        )]);

                                        var lineSplits = turf.lineSplit(line, poly);

                                        if (arrayUtilities.isNullOrEmpty(lineSplits.features)) {

                                            // None of the line parts intersect, so its either fully within or fully without.


                                            if (turf.booleanPointInPolygon([prevCoord.lat, prevCoord.lng], poly) || turf.booleanPointInPolygon([coord.lat, coord.lng], poly)) {
                                                renderPositions.push([prevCoord.lat, prevCoord.lng]);
                                                renderPositions.push([coord.lat, coord.lng]);
                                            }

                                        } else {


                                            // Iterate over the line parts and just include the ones that are inside the poly

                                            lineSplits.features.forEach((feature) => {

                                                feature.geometry.coordinates.forEach((coordinate, coordinateIndex) => {

                                                    if (coordinateIndex > 0) {
                                                        let prev = feature.geometry.coordinates[coordinateIndex - 1];

                                                        let p0 = { lat: prev[0], lng: prev[1] };
                                                        let p1 = { lat: coordinate[0], lng: coordinate[1] };

                                                        let midPoint = geospatialUtilities.midPoint(p0, p1);

                                                        if (turf.booleanPointInPolygon([midPoint.lat, midPoint.lng], poly)) {
                                                            renderPositions.push(prev);
                                                            renderPositions.push(coordinate);
                                                        }
                                                    }
                                                });
                                            });
                                        }

                                    }
                                })
                            } else if (geometryType === enums.GeometryType[2]) {

                                let p1 = turf.polygon([geospatialUtilities.makePolygonSelfClosing(
                                    geospatialUtilities.coordsToLatLngArray(coords)
                                )]);

                                let p2 = turf.polygon([geospatialUtilities.makePolygonSelfClosing(
                                    geospatialUtilities.coordsToLatLngArray(clipToPolygon)
                                )]);

                                var intersection = turf.intersect(p1, p2);

                                if (intersection) {
                                    renderPositions = intersection.geometry.coordinates;
                                } else {
                                    renderPositions = null;
                                }
                            }
                        }


                        if (!arrayUtilities.isNullOrEmpty(midPoints)) {
                            renderMidPoints = [];

                            midPoints.forEach((midPoint) => {
                                if (geospatialUtilities.polygonContainsPoint(clipToPolygon, midPoint)) {
                                    renderMidPoints.push(midPoint);
                                }
                            });
                        }
                    }
                }
            }

            setRenderPositions(renderPositions);
            setRenderMidPoints(renderMidPoints);
        }

    }, [printingSketchLimitIndex, printingWorkflowId])

    useEffect(() => {
        setRenderMidPoints(midPoints);
    }, [midPoints])

    useEffect(() => {
        reset();

        const subscriptions = [
            postal.subscribe({
                channel: "map",
                topic: "printing",
                callback: function (data) {
                    setPrintingSketchLimitIndex(data.sketchLimitIndex);
                    setPrintingWorkflowId(data.workflowId);
                }
            }),
        ]

        return () => {
            subscriptions.forEach(subscription => {
                subscription.unsubscribe();
            });
        }
    }, [])

    let pathOptions = {
        color,
        weight: 3,
        fill: false,
        dashArray: isDashed ? "5 10" : null
    };

    let bufferPathOptions = { color, weight: 20, opacity: 0.00, fill: false };

    let popup = <WhenMapIsNotDrawingOrEditing>
        <Popup>
            <div className='card border-0'>
                <div className='card-body position-relative'>
                    <h5 className="card-title mb-4">Workflow</h5>
                    <div className="d-flex justify-content-between">
                        <Button size="sm" variant='secondary' onClick={() => setIsEditing(true)}>Edit</Button>
                        <DeleteButton modalTitle={`Delete Workflow`} modalContent={`Are you sure you want to permanently delete this workflow?`} onConfirm={onDelete} />
                    </div>
                </div>
            </div>
        </Popup>
    </WhenMapIsNotDrawingOrEditing>;

    return <>
        {isVisible && <>
            {geometryType === enums.GeometryType[1] && !arrayUtilities.isNullOrEmpty(renderPositions) && <>

                <Polyline positions={renderPositions} pathOptions={bufferPathOptions} bubblingMouseEvents={false} eventHandlers={{
                    dblclick: (e) => setIsEditing(true)
                }}>
                    {popup}
                </Polyline>
                <Polyline positions={renderPositions} pathOptions={pathOptions} />


                {hasIcon && !arrayUtilities.isNullOrEmpty(renderMidPoints) && renderMidPoints.map((midPoint, index) =>


                    <Marker position={[midPoint.lat, midPoint.lng]} icon={icon} index={index} key={`workflow-drawing-tool-midpoint-${index}`} draggable={isEditing} bubblingMouseEvents={false}
                        eventHandlers={{
                            dragend: (e) => onMidPointMarkerDragEnd(e, index)
                        }}>
                        {isEditing ? <Popup>
                            <div className='card border-0'>
                                <div className='card-body position-relative d-flex justify-content-center'>
                                    <Button size="sm" variant='danger' onClick={() => deleteMidPoint(index)}>Delete mid-point</Button>
                                </div>
                            </div>
                        </Popup> : popup}
                    </Marker>
                )}

                {defaultIcon !== null && (isDrawing || isEditing) && renderPositions.map((coord, index) => <SnappableMarker
                    index={index}
                    key={`workflow-drawing-tool-point-${index}`}
                    icon={defaultIcon}
                    position={coord}
                    onDragend={(e, index) => onSnappableMarkerDragEnd(e.latlng, index)}
                    snappingTolerance={props.snappingTolerance}
                    sketch={props.sketch}
                    points={props.points}
                    lines={props.lines}
                    workflows={props.workflows}
                    workflowLines={props.workflowLines}
                    workflowPoints={props.workflowPoints}
                    workflowPointsLines={props.workflowPointsLines} >

                    <Popup>
                        <div className='card border-0'>
                            <div className='card-body position-relative d-flex justify-content-center'>
                                <Button size="sm" variant='danger' onClick={(e) => deletePoint(e, index)}>Delete Point</Button>
                            </div>
                        </div>
                    </Popup>
                </SnappableMarker>)}

            </>}

            {geometryType === enums.GeometryType[0] && icon !== null && !arrayUtilities.isNullOrEmpty(renderPositions) && <>

                {renderPositions.map((coord, index) => <Marker
                    index={index}
                    key={`workflow-drawing-tool-point-${index}`}
                    draggable={isDrawing || isEditing}
                    icon={icon}
                    position={coord}
                    bubblingMouseEvents={false}
                    eventHandlers={{
                        dragend: (e) => onMarkerDragEnd(e, index)
                    }}>
                    {(isDrawing || isEditing) &&
                        <WhenMapIsNotDrawingOrEditing>
                            <Popup>
                                <div className='card border-0'>
                                    <div className='card-body position-relative d-flex justify-content-center'>
                                        <Button size="sm" variant='danger' onClick={(e) => deletePoint(e, index)}>Delete Point</Button>
                                    </div>
                                </div>
                            </Popup>
                        </WhenMapIsNotDrawingOrEditing>
                    }

                    {!isDrawing && !isEditing && popup}
                </Marker>)})
            </>}
        </>
        }


        {doSnapToPoint !== null &&
            <SnapToPoint
                latlng={doSnapToPoint}
                snappingTolerance={props.snappingTolerance}
                sketch={props.sketch}
                points={props.points}
                lines={props.lines}
                workflows={props.workflows}
                onSnappedPointSelected={onAddPoint}
                onCancel={() => setDoSnapToPoint(null)}
                workflowLines={props.workflowLines}
                workflowPoints={props.workflowPoints}
                workflowPointsLines={props.workflowPointsLines} />
        }

        <ConfigurationDialog show={isDrawing || isEditing} title={`${isDrawing ? "Add" : "Edit"} Workflow`} onCancel={onCancel} onSave={onSave}>
            <WorkflowSelect value={workflowId} onChange={setWorkflowId} workflows={props.workflows} />

            <Form.Group controlId='input-small' className='mb-3'>
                <Form.Label className='text-body fs-sm me-2 pe-1 text-nowrap'>
                    Type
                </Form.Label>
                <Form.Select size='sm' value={type} onChange={(e) => setType(e.target.value)}>
                    <option value=""></option>
                    {!arrayUtilities.isNullOrEmpty(types) && <>
                        {types.map((type, index) => <option index={index} key={`type-${type}`} value={type}>{type}</option>)}
                    </>}
                </Form.Select>
            </Form.Group>

            <LimitSelect value={limitId} onChange={setLimitId} limits={props.sketch?.limits} />
            <ScaleSlider value={scale} onChange={setScale} />
            <RotationSlider value={rotate} onChange={setRotate} />

        </ConfigurationDialog>
    </>
}