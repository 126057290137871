import { Button, Form, Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import Textbox from "./form/textbox";
import stringUtilities from "../utilities/stringUtilities";

export default function OrganisationModal(props) {
    const [newName, setNewName] = useState(props.org.name);
    const [newFieldUserLimit, setNewFieldUserLimit] = useState(String(props.org.fieldUserLimit));
    const [newOfficeUserLimit, setNewOfficeUserLimit] = useState(String(props.org.officeUserLimit));
    const [canSave, setCanSave] = useState(false);

    const handleCancel = () => {
        if (props.onCancel) {
            props.onCancel()
        }
    }

    useEffect(() => {
        setCanSave(!stringUtilities.isNullOrEmpty(newName) && !stringUtilities.isNullOrEmpty(newFieldUserLimit) && !stringUtilities.isNullOrEmpty(newOfficeUserLimit))
    }, [newName, newFieldUserLimit, newOfficeUserLimit])

    return (
        <Modal show onHide={ handleCancel } centered>
            <Modal.Header closeButton>
                <Modal.Title>Update Organisation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Textbox label="Name" value={ newName } onChange={ setNewName } />
                <Form.Group className="mb-3">
                    <Form.Label className='text-body fs-sm me-2 pe-1 text-nowrap'>Field User Limit</Form.Label>
                    <Form.Control
                        size='sm' value={ String(newFieldUserLimit) } type="number" set="1"
                        onChange={ e => {
                            setNewFieldUserLimit(e.target.value)
                        } } />
                </Form.Group>
                <Form.Group>
                    <Form.Label className='text-body fs-sm me-2 pe-1 text-nowrap'>Office User Limit</Form.Label>
                    <Form.Control
                        size='sm' value={ String(newOfficeUserLimit) } type="number" set="1"
                        onChange={ e => setNewOfficeUserLimit(e.target.value) } />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-end">
                <div>
                    <Button size="sm" variant='secondary' onClick={ handleCancel } className="me-2">Cancel</Button>
                    <Button size="sm" variant='primary' onClick={ () => props.handleSave(newName, newFieldUserLimit, newOfficeUserLimit) } disabled={ !canSave }>Save</Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}